<template>
  <div class="mb-80 main-box">
    <div class="table-header-area">
      <div class="table-header">
        <div class="tab-back">
          <span class="table-title">今日中标企业（{{ total1 }}） </span>
        </div>
      </div>
      <div class="right-header">
        <span class="view-more" @click="getPageOpen">查看更多</span>
      </div>
    </div>
    <generic-table
      :hasSnCol="false"
      :get-data="getPageData1"
      :columns="displayColumns1"
      :page-size="20"
      :hide-pagination="true"
      @linkAction="onLinkAction"
    ></generic-table>
    <div class="table-header-area">
      <div class="table-header">
        <div class="tab-back">
          <span class="table-title">中标项目经理（{{ total2 }}）</span>
        </div>
      </div>
      <div class="right-header">
        <span class="view-more" @click="getManageOpen">查看更多</span>
      </div>
    </div>
    <generic-table
      :hasSnCol="false"
      :get-data="getPageData2"
      :columns="displayColumns2"
      :page-size="20"
      :hide-pagination="true"
      @linkAction="onLinkAction"
    ></generic-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericTable from "@/components/generic-table";
import { getTenderInvitationPageUnLogin } from "@/api/aptitude";

export default {
  components: {
    GenericTable,
  },
  data() {
    return {
      total1: 0,
      total2: 0,
      displayColumns1: [
        {
          label: "企业名称",
          prop: "winningBidder",
          minWidth: 25,
          hasLink: true,
        },
        { label: "中标项目", prop: "projectName", minWidth: 25, hasLink: true },
        { label: "业主单位", prop: "biddingUnit", minWidth: 25 },
        { label: "项目金额", prop: "winningAmount", minWidth: 25 },
      ],
      displayColumns2: [
        {
          label: "项目经理",
          prop: "winningLeader",
          minWidth: 25,
          hasLink: true,
        },
        { label: "中标项目", prop: "projectName", minWidth: 50, hasLink: true },
        { label: "中标时间", prop: "winningDate", minWidth: 25 },
      ],
    };
  },
  computed: {
    ...mapState("system", ["visitTotal", "registerUserTotal","todayVisiNum"]),
  },
  mounted(){
    document.title = '企业查询-首页' 
  },
  methods: {
    async getPageData1({ size, current }) {
      // 获取数据的 API 请求
      const response = await getTenderInvitationPageUnLogin({
        size,
        current,
      });

      let records = response.data ? response.data.records : [];
      let total = response.data ? response.data.total : 0;
      this.total1 = total;
      return {
        data: records,
        total: total,
      };
    },
    async getPageData2({ size, current }) {
      // 获取数据的 API 请求
      const response = await getTenderInvitationPageUnLogin({
        size,
        current,
      });

      let records = response.data ? response.data.records : [];
      let total = response.data ? response.data.total : 0;
      this.total2 = total;
      return {
        data: records,
        total: total,
      };
    },
    getPageOpen() {
      this.$router.push({ path: "/home/detail/" });
    },
    getManageOpen() {
      this.$router.push({ path: "/home/detailManage/" });
    },
    onLinkAction(prop, row) {
      console.log("linkAction", prop, row);
      if (prop == "winningBidder") {        
        this.$router.push({
            path: "/qualification/detail/",
            query: { unicode: row.winningBidderCode },
        });
      } else if (prop == "winningLeader") {
        this.$router.push({
            path: "/personnel/detail/",
            query: { code: row.winningLeaderCode },
        });
      } else if (prop == "projectName") {
        this.$router.push({
            path: "/project/details/",
            query: { prjnum: row.projectNo },
        });
      }
    },
  },
};
</script>
<style scoped>
.main-box{
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.mb-80 {
  margin-bottom: 80px;
}
.table-header-area {
  display: flex;
}
.table-header {
  width: 520px;
  height: 60px;
  /* background: linear-gradient(90deg, #1890ff 0%, #ffffff 100%); */
  background-blend-mode: lighten;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
  background-image: url(~@/assets/images/bjtsy/jx7771.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.tab-back {
  padding-left: 20px;
  background: linear-gradient(90deg, #1890ff -10%, #ffffff20 100%);
}

.table-title {
  width: 233px;
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 60px;
}

.right-header {
  text-align: right;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 20px;
  flex: 1;
  cursor: pointer;
}
.view-more {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #888888;
  line-height: 60px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.view-more::after {
  content: "";
  background-image: url(~@/assets/images/more11941.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
